import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto change-pass`}</strong>{` -- change password of an encrypted private key (PEM or JWK format)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto change-pass <key-file>
[--out=<file>] [--password-file=<file>] [--new-password-file=<file>]
[--insecure] [--no-password]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto change-pass`}</strong>{` extracts and decrypts
the private key from a file and encrypts and serializes the key to disk using a
new password.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
The PEM or JWK file with the encrypted key.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the private key.`}</p>
    <p><strong parentName="p">{`--new-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt the private key.`}</p>
    <p><strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--output-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` new encrypted key path. Default to overwriting the `}<inlineCode parentName="p">{`key`}</inlineCode>{` positional argument`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--insecure`}</strong></p>
    <p><strong parentName="p">{`--no-password`}</strong>{`
Do not ask for a password to encrypt a private key. Sensitive key material will
be written to disk unencrypted. This is not recommended. Requires `}<strong parentName="p">{`--insecure`}</strong>{` flag.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Change password for PEM formatted key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto change-pass key.pem
`}</code></pre>
    <p>{`Remove password for PEM formatted key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto change-pass key.pem --no-password --insecure
`}</code></pre>
    <p>{`Change password for PEM formatted key and write encrypted key to different file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto change-pass key.pem --out new-key.pem
`}</code></pre>
    <p>{`Change password for JWK formatted key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto change-pass key.jwk
`}</code></pre>
    <p>{`Removed password for JWK formatted key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto change-pass key.jwk --no-password --insecure
`}</code></pre>
    <p>{`Change password for JWK formatted key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto change-pass key.jwk --out new-key.jwk
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      